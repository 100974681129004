import React, { FC } from 'react'
import { INewsItemHeader } from 'src/components/news/list/news-list-types'
import NewsItemTile from 'src/components/news/list/NewsItemTile'
import NewsImageTile from 'src/components/news/list/NewsImageTile'
import styled from 'styled-components'
import tw from 'twin.macro'

interface INewsGridProps {
  items: INewsItemHeader[]
  maxVisibleCount: number
}

const Grid = styled.div`
  div:nth-child(even) {
    ${tw`md:flex-row-reverse`}
  }
  div:nth-child(odd) {
    ${tw`md:flex-row`}
  }
`
const NewsGrid: FC<INewsGridProps> = ({ items, maxVisibleCount }) => (
  <Grid>
    {items.slice(0, maxVisibleCount).map((item) => (
      <div key={item.id} className="md:flex md:flex-col">
        <NewsItemTile
          className="flex-1"
          date={item.date}
          title={item.title}
          slug={item.slug}
          locale={item.locale}
        />
        <NewsImageTile
          className="flex-1"
          url={item.listImage.url}
          alt={item.listImage.alt}
          slug={item.slug}
        />
      </div>
    ))}
  </Grid>
)

export default NewsGrid
